export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'primary',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: '#d1c270',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem']
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      ':hover': {
        color: 'inherit'
      }
    },
    '.hamburger > div': {
      backgroundColor: 'white'
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        maxHeight: ['55px', '', '100px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem', '0.75rem', '1rem', '0.75rem', '0.75rem', '1rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: '#4d442d',
      paddingBottom: '1rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem']
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light'
        }
      }
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: '#c6a34d'
      }
    },
    '.logo': {
      img: {
        opacity: '1',
        maxHeight: ['65px', '65px']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['55px', '55px']
      }
    },
    '.navItemDropdown': {
      alignSelf: 'center'
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'black',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2.5rem',
    order: '2',
    color: 'primary',
    fontWeight: '500',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: '#347447',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: '0.85rem',
    textTransform: 'uppercase'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: '100vh'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '2',
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: '100vh'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem',
      height: 'auto'
    },
    '.title': {
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '', '3rem'],
      fontWeight: '300'
    },
    '.subtitle': {},
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'black',
      color: 'white'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    minHeight: ['', '', '95vh'],
    '.imageContainer': {
      order: ['3']
    },
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      border: 'none',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      width: 'fit-content',
      fontSize: '0rem',
      color: 'background',
      '::before': {
        variant: 'customVariants.title',
        content: "'Recent News'" // ! bad coding but could't be bothered to shadow
      }
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: '#357548',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageReservations: {
    variant: 'customVariants.sideBySide2'
  },

  homepagePrivateEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageWeddingEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageHours: {
    variant: 'customVariants.sideBySide1'
  },

  homepageJoinMailList: {
    variant: 'customVariants.sideBySide2'
  },

  // homepageSlider: {
  //   padding: '0rem',
  //   '.slick-dots': {
  //     display: 'none !important',
  //   },
  // },
  homepageTestimonials: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageBoxes: {
    padding: '0rem',
    'div:nth-of-type(6)': {
      backgroundColor: '#C6A34D'
    },
    '.box': {
      padding: '0rem',
      width: ['100%', '', '50%', '33.3%'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px'
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '-1',
      objectFit: 'cover',
      filter: 'brightness(0.55)'
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem'],
      color: 'white'
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400',
      color: 'white'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: '0rem 2rem 3rem',
      order: '4',
      borderColor: 'white',
      color: 'white',
      textDecoration: 'underline',
      width: 'fit-content',
      textTransform: 'uppercase'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  aboutpageTeam: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: ''
      }
    }
  },

  aboutpageHistory: {
    variant: 'customVariants.sideBySide2'
    // '.lazyload-wrapper': {
    //   order: '2',
    //   width: ['', '', '55%'],
    //   '::before': {
    //     background: 'unset',
    //   },
    //   img: {
    //     height: '',
    //   },
    // },
  },
  aboutpageCareer: {
    variant: 'customVariants.sideBySide1'
    // '.lazyload-wrapper': {
    //   width: ['', '', '55%'],
    //   '::before': {
    //     background: 'unset',
    //   },
    //   img: {
    //     height: '',
    //   },
    // },
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      textTransform: 'uppercase',
      color: 'primary',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem'],
      paddingBottom: '1rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem']
    },

    '.menuItemName': {
      color: '#235825',
      fontFamily: 'heading'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem', '', '1rem 1.5rem']
    },
    '.menuItemDescription': {
      color: 'primary'
    },
    '.menuPriceContainer': {
      fontFamily: 'heading'
      // display: 'none',
    },
    '.variantsContainer': {
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: '#c6a34d',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Reservations  ====
  // ? ========================

  reservations: {
    padding: ['2rem 1rem', '', '5rem 1rem'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    paddingTop: [0, 0, 0, 0],
    backgroundColor: 'transparent',
    '.box': {
      width: ['100%', '', 'calc(50% - 1rem)', 'calc(25% - 1rem)'],
      backgroundColor: 'transparent'
    },
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'transparent' //'#347447b0',
    },
    '.title, .subtitle, .text': {
      // color: 'black',
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
